import { useState, React, useEffect, useRef } from 'react';
import {
  Card,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  RadioGroup,
  Slider,
  StepButton,
  Switch,
  createFilterOptions
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import './CreateAgency.css';
import Paper from '@mui/material/Paper';
import { Autocomplete, Button, TextField } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import { useHistory } from 'react-router-dom';
import { CardContent, Radio, FormControlLabel } from '@mui/material';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import {
  AdminGetCarriers,
  AgencyAdminGetProfile,
  CreateOrUpdateAgency,
  GetLocationsJSON,
  UploadAgencyLogo
} from '../../apis/Admin';
import { ErrorToast, successToast } from '../../components/Toasts';
import UploadIcon from '@mui/icons-material/Upload';
import { ToastContainer } from 'react-toastify';
import NoLogo from '../../assets/carrier logos/No Logo.png';
import {
  bas,
  base_logo_url,
  base_logo_urle_logo_urlbase_logo_url
} from '../../apis/constants';
import Cropper from 'react-easy-crop';
import getCroppedImg from './cropImage';

export default function CreateAgency(props) {
  const EditedData = props?.location?.state;
  const history = useHistory();
  const [postData, setPostData] = useState({});
  const [shipmentFailed, setShipmentFailed] = useState(false);
  const [isValidCity, setIsValidCity] = useState(false);
  const [isValidCountry, setIsValidCountry] = useState(false);
  const [isValidState, setIsValidState] = useState(false);

  const [activeStep, setActiveStep] = useState(0);
  const [activeTabLabel, setActiveTabLabel] = useState('Detail');
  // agency states
  const options = ['Active', 'In-Active'];
  const [agencyActiveValue, setAgencyActiveValue] = useState(
    EditedData?.active === false ? options[1] : options[0]
  );
  const [agencyStatus, setAgencyStatus] = useState(true);
  const [companyName, setCompanyName] = useState(
    EditedData?.company ? EditedData?.company : ''
  );
  const [address1, setAddress1] = useState(
    EditedData?.address1 ? EditedData?.address1 : ''
  );
  const [address2, setAddress2] = useState(
    EditedData?.address2 ? EditedData?.address2 : ''
  );
  const [zip, setZip] = useState(
    EditedData?.zipCode ? EditedData?.zipCode : ''
  );
  const [city, setCity] = useState(EditedData?.city ? EditedData?.city : '');
  const [state, setState] = useState(
    EditedData?.state ? EditedData?.state : ''
  );
  const [country, setCountry] = useState(
    EditedData?.country ? EditedData?.country : ''
  );
  const [createAgencyLoader, setCreateAgencyLoader] = useState(false);
  const [createAgencyOnce, setCreateAgencyOnce] = useState(false);
  const [agencyImage, setAgencyImage] = useState(
    EditedData?.logo ? base_logo_url + EditedData?.logo : null
  );
  const [agencyApiUploadedImage, setAgencyApiUploadedImage] = useState(
    EditedData?.logo ? EditedData?.logo : ''
  );
  const [value, setValue] = useState('All');

  // validations
  const [validationErrors, setValidationErrors] = useState({});
  const [selectedValue, setSelectedValue] = useState('');
  // Carriers state
  const [phoneNumberValidError, setPhoneNumberValidError] = useState(false);

  const [carriersLoader, setCarriersLoader] = useState(true);

  const [carriers, setCarriers] = useState([]);
  const [LTLCarriers, setLTLCarriers] = useState([]);
  const [TLCarriers, setTLCarriers] = useState([]);
  const [TankerCarriers, setTankerCarriers] = useState([]);
  const [AllCarriers, setAllCarriers] = useState([]);
  const [allCarriersChecked, setAllCarriersChecked] = useState(false);

  const [selectedCarriersIdArray, setSelectedCarriersIdArray] = useState([]);
  // admin States
  const [firstName, setFirstName] = useState(
    EditedData?.adminInfo?.firstName ? EditedData?.adminInfo?.firstName : ''
  );
  const [lastName, setLastName] = useState(
    EditedData?.adminInfo?.lastName ? EditedData?.adminInfo?.lastName : ''
  );
  const [email, setEmail] = useState(
    EditedData?.adminInfo?.email ? EditedData?.adminInfo?.email : ''
  );
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState(
    EditedData?.adminInfo?.phone ? EditedData?.adminInfo?.phone : ''
  );
  const [isValid, setIsValid] = useState(true);
  const [adminActiveValue, setAdminActiveValue] = useState(options[0]);
  const [adminStatus, setAdminStatus] = useState(true);
  const [adminImage, setAdminImage] = useState(null);
  const [cityStateZipHoveredValue, setCityStateZipHoveredValue] =
    useState(null);
  const [shipCity, setShipCity] = useState([]);
  const [shipFromErr, setShipFromErr] = useState(false);
  const [cityStateZipFinalValue, setCityStateZipFinalValue] = useState(
    EditedData
      ? EditedData?.city +
          ' ' +
          EditedData?.state +
          ' ' +
          EditedData?.zipCode +
          ' ' +
          EditedData?.country
      : ''
  );
  const [cityStateZipFinalAddress, setCityStateZipFinalAddress] = useState(
    EditedData
      ? EditedData?.city +
          ' ' +
          EditedData?.state +
          ' ' +
          EditedData?.zipCode +
          ' ' +
          EditedData?.country
      : ''
  );
  const selectShipTo = useRef(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [tempImage, setTempImage] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [shipFromloading, setShipFromloading] = useState(false);

  const handleAgencyImageUpload = event => {
    const file = event.target.files[0];
    const reader = new FileReader();
    const imageType = /image.*/;

    if (file && !file?.type?.match(imageType)) {
      ErrorToast('Only image files are allowed');
      return;
    }
    if (file) {
      reader.onloadend = () => {
        setTempImage(reader.result);
        setDialogOpen(true);
      };
      reader.readAsDataURL(file);
      event.target.value = null;
    }
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  // const handleConfirm = async () => {
  //   try {
  //     console.log('Starting image crop process...');
  //     const croppedImage = await getCroppedImg(tempImage, croppedAreaPixels);
  //     console.log('Image cropped successfully:', croppedImage);

  //     setAgencyImage(croppedImage);
  //     setDialogOpen(false);

  //     // Extract original file name from base64 data URL
  //     const originalFileNameWithExtension = tempImage.match(
  //       /^data:image\/([a-zA-Z]*);base64,/
  //     )[1];
  //     console.log(
  //       'Original file name with extension:',
  //       originalFileNameWithExtension
  //     );

  //     // Remove the image type prefix and suffix to get the file name without extension
  //     const fileNameWithoutExtension = originalFileNameWithExtension
  //       .split('.')
  //       .slice(0, -1)
  //       .join('.');
  //     console.log('File name without extension:', fileNameWithoutExtension);

  //     // Add .png extension (assuming always png for now)
  //     const newFileName = `${fileNameWithoutExtension}.${originalFileNameWithExtension}`;
  //     console.log('New file name:', newFileName);

  //     let formData = new FormData();
  //     const file = dataURLtoFile(croppedImage, newFileName);
  //     console.log('Converted cropped image to file:', file);

  //     formData.append('file', file);

  //     const response = await UploadAgencyLogo(formData);
  //     if (response.status === 200) {
  //       console.log('Image uploaded successfully:', response.data.data);
  //       setAgencyApiUploadedImage(response.data.data);
  //     } else {
  //       throw new Error('Failed to upload image');
  //     }
  //   } catch (error) {
  //     ErrorToast('Failed to crop the image');
  //     console.error('Error during image cropping and upload process:', error);
  //   }
  // };
  const handleCancel = () => {
    setDialogOpen(false);
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setCroppedAreaPixels(null);
    setTempImage(null);
  };
  const handleConfirm = async () => {
    try {
      const croppedImage = await getCroppedImg(tempImage, croppedAreaPixels);

      setAgencyImage(croppedImage);
      setDialogOpen(false);
      // Ensure tempImage is a valid data URL
      if (!tempImage.startsWith('data:image/')) {
        throw new Error('Invalid data URL format');
      }

      // Regex pattern to match and capture the original file name from data URL
      const regex = /^data:image\/[a-zA-Z]*;base64,([^]*)$/;
      const match = tempImage.match(regex);
      if (!match || match.length < 2) {
        throw new Error('Failed to extract image data');
      }

      // Decode the base64 data to get the file name
      const imageData = match[1];
      const blob = base64ToBlob(imageData); // Implement base64ToBlob function to convert base64 to Blob

      // Generate a random file name
      const originalFileName = generateRandomFileName(blob.type);

      let formData = new FormData();
      const file = dataURLtoFile(croppedImage, originalFileName);

      formData.append('file', file);

      const response = await UploadAgencyLogo(formData);
      if (response.status === 200) {
        setAgencyApiUploadedImage(response.data.data);
      } else {
        throw new Error('Failed to upload image');
      }
    } catch (error) {
      ErrorToast('Failed to crop the image');
      console.error('Error during image cropping and upload process:', error);
    }
  };

  // Function to convert base64 to Blob
  const base64ToBlob = base64Data => {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: 'image/png' }); // Adjust type as per your image type
  };

  // Function to generate a random file name
  const generateRandomFileName = imageType => {
    const timestamp = new Date().getTime();
    const randomString = Math.random().toString(36).substring(7); // Random string of 7 characters
    const extension = imageType.split('/')[1]; // Extract the extension from image type

    const randomFileName = `${timestamp}_${randomString}.${extension}`;
    return randomFileName;
  };
  // Helper function to convert data URL to File object
  const dataURLtoFile = (dataUrl, fileName) => {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], fileName, { type: mime });
  };

  const handleUSAddresses = (apiRes, type) => {
    setCityStateZipHoveredValue(apiRes[0]);
    setShipCity(apiRes);
    setShipFromloading(false);
  };

  const handleAllActiveCarriers = event => {
    setAllCarriersChecked(event.target.checked);
    for (let i = 0; i < carriers.length; i++) {
      // const element = array[i];
      const tempIDs = [...selectedCarriersIdArray];
      tempIDs[i].assigned = event.target.checked;
      setSelectedCarriersIdArray(tempIDs);
      setCarriers(prevArray => {
        const newArray = [...prevArray];
        newArray[i].active = event.target.checked;
        return newArray;
      });
    }
  };

  const handleRadioChange = event => {
    setSelectedValue(event.target.value);
  };
  const handleActiveAllCarriersSwitch = TempCarrierIDs => {
    const StatusArray = [];
    for (let i = 0; i < TempCarrierIDs.length; i++) {
      StatusArray.push(
        TempCarrierIDs[i].assigned !== undefined
          ? TempCarrierIDs[i].assigned
          : TempCarrierIDs[i].active
      );
    }
    const allTrue = StatusArray.every(value => value === true);
    if (allTrue) {
      setAllCarriersChecked(true);
    } else {
      setAllCarriersChecked(false);
    }
  };
  const CheckSelectedCarriers = (CarriersArray, TempCarrierIDs) => {
    if (EditedData?.carriers) {
      const PropsCarrierArray = EditedData?.carriers;
      const temp = [...CarriersArray];
      const tempIDs = [...TempCarrierIDs];
      // for (let i = 0; i < PropsCarrierArray.length; i++) {
      //   const CarrierName = PropsCarrierArray[i].name;
      //   const CarrierID = PropsCarrierArray[i].id;
      //   tempIDs[i].id === CarrierID
      //     ? (tempIDs[i].assigned = true)
      //     : (tempIDs[i].assigned = false);
      // }

      for (let i = 0; i < CarriersArray.length; i++) {
        const staticId = CarriersArray[i].id;
        if (
          PropsCarrierArray.some(
            element => element.id === staticId && element.active
          )
        ) {
          temp[i].active = true;
          tempIDs[i].assigned = !tempIDs[i].assigned;
        }
      }

      handleActiveAllCarriersSwitch(TempCarrierIDs);
      setSelectedCarriersIdArray(tempIDs);
      setCarriers(temp);
    }
  };

  const handleSuccessCarriers = ApiRes => {
    setValue('All');
    setCarriersLoader(false);
    const CarriersArray = ApiRes?.data?.data.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    const LTL = CarriersArray.filter(items => items.cType === 'LTL');
    const TL = CarriersArray.filter(items => items.cType === 'TL');
    const Parcel = CarriersArray.filter(items => items.cType === 'Parcel');
    const TempCarrierIDs = [];
    for (let i = 0; i < CarriersArray.length; i++) {
      CarriersArray[i].active = false;
      TempCarrierIDs.push({ carrierId: CarriersArray[i].id, assigned: false });
    }
    setCarriers(CarriersArray);
    setLTLCarriers(LTL);
    setTLCarriers(TL);
    setTankerCarriers(Parcel);
    setAllCarriers(CarriersArray);
    setSelectedCarriersIdArray(TempCarrierIDs);
    EditedData && CheckSelectedCarriers(CarriersArray, TempCarrierIDs);
  };

  const handleSuccessCreateAgency = ApiRes => {
    setCreateAgencyLoader(false);
    successToast(
      EditedData?.state
        ? 'Agency has been Updated.'
        : 'Congratulations! Agency has been created successfully.'
    );
    setTimeout(() => {
      history.push('/manage-agencies');
    }, 4000);
  };

  const handdleExistedUserValidation = () => {
    setCreateAgencyOnce(false);
    const errors = {};
    errors['emailError'] = 'User exist with this email try another one';
    setValidationErrors(errors);
  };

  const handleCreateUpdateAgencyReq = () => {
    setCreateAgencyLoader(true);
    const CreateOrUpdateAgencyObj = {
      id: EditedData?.id ? EditedData?.id : 0,
      name: companyName,
      logo: agencyApiUploadedImage,
      url: '',
      active: agencyStatus,
      address: address1,
      address2: address2,
      zipCode: cityStateZipFinalAddress.ZipCode,
      city: cityStateZipFinalAddress.City,
      state: cityStateZipFinalAddress.State,
      country: cityStateZipFinalAddress.CountryCode,
      subscriptioPlanId: 1,
      carriers: selectedCarriersIdArray.filter(items => items.assigned),
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phoneNumber
    };
    setPostData(CreateOrUpdateAgencyObj);
    if (!createAgencyOnce) {
      setCreateAgencyOnce(true);
      CreateOrUpdateAgency(CreateOrUpdateAgencyObj)
        .then(res => res.status === 200 && handleSuccessCreateAgency(res))
        .catch(err => {
          ErrorToast(err?.response?.data);
          setCreateAgencyLoader(false);
          setCreateAgencyOnce(false);
        });
      setPostData({});
    }
  };

  const ValidatUserEmail = () => {
    AgencyAdminGetProfile(email)
      .then(res => res.status === 200 && handdleExistedUserValidation())
      .catch(err => handleCreateUpdateAgencyReq());
  };

  const handleSuccessGetAgencyDetails = ApiRes => {
    const AgencyAdminDetails = ApiRes?.data?.data?.userInfo;
    console.log(AgencyAdminDetails, 'AgencyAdminDetails');
    setFirstName(AgencyAdminDetails.firstName);
    setLastName(AgencyAdminDetails.lastName);
    setEmail(AgencyAdminDetails.email);
    setPhoneNumber(AgencyAdminDetails.phone);
  };

  const handleGetCarriersRequest = value => {
    AdminGetCarriers(value)
      .then(res => res.status === 200 && handleSuccessCarriers(res))
      .catch(err => {
        ErrorToast('Network Error');
        setCarriersLoader(false);
      });
  };

  const handleNext = index => {
    let errors = {};
    if (index === 0) {
      if (!companyName) {
        errors['companyNameError'] = 'Enter Company Name';
      }
      // else if (!zip) {
      //   errors['zipError'] = 'Enter Zip';
      // } else if (!city) {
      //   errors['cityError'] = 'Enter City';
      // } else if (isValidCity) {
      //   errors['cityError'] = 'Enter Valid City';
      // } else if (!state) {
      //   errors['stateError'] = 'Enter State or Province';
      // } else if (isValidState) {
      //   errors['stateError'] = 'Enter Valid State or Province';
      // } else if (!country) {
      //   errors['countryError'] = 'Enter Country';
      // } else if (isValidCountry) {
      //   errors['countryError'] = 'Enter Valid Country';
      // }
      else if (!cityStateZipFinalValue) {
        errors['cityStateZipFinalValue'] = 'Enter City / State / Zip / Country';
      } else setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
    setValidationErrors(errors);
    if (index === 1) {
      selectedCarriersIdArray.length === 0 && handleGetCarriersRequest(3);
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
    if (index === 2) {
      // const AgencyAdminDetails = ApiRes?.data?.data?.userInfo;
      setActiveStep(prevActiveStep => prevActiveStep + 1);

      // AgencyAdminGetProfile(EditedData?.adminLoginId)
      //   .then(res => res.status === 200 && handleSuccessGetAgencyDetails(res))
      //   .catch(err =>
      //     ErrorToast('Cant get agency details due to newtork failed')
      //   );
    }
    if (index === 3) {
      if (!firstName) {
        errors['firstNameError'] = 'Enter First Name';
      } else if (!lastName) {
        errors['lastNameError'] = 'Enter Last Name';
      } else if (!email) {
        errors['emailError'] = 'Enter Email';
      } else if (!phoneNumber) {
        errors['phoneNumberError'] = 'Enter Phone Number';
      } else if (!isValidEmail) {
        errors['emailError'] =
          'Valid email, will be your agency admin account.';
      } else if (phoneNumberValidError) {
        errors['phoneNumberError'] = 'Enter Valid Phone Number (XXX) XXX-XXXX';
      } else if (!createAgencyOnce) {
        setCreateAgencyOnce(true);
        EditedData ? handleCreateUpdateAgencyReq() : ValidatUserEmail();
      }
    }
  };
  const firstLetter = firstName ? firstName.charAt(0).toUpperCase() : '';

  const lastLetter = lastName ? lastName.charAt(0).toUpperCase() : '';

  const imageStyle = {
    marginTop: 50,
    marginLeft: 20,
    width: '120px',
    height: '120px',
    borderRadius: '50%',
    backgroundColor: '#f0f0f0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#333'
  };
  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleBackShipment = () => {
    setShipmentFailed(false);
    handleReset();
  };
  const handleStep = (step, label) => () => {
    setActiveStep(step);
    setActiveTabLabel(label);
  };

  const CreateAgencySteps = [
    'Detail',
    'Subscription',
    'Carriers',
    'Admin Info'
  ];
  const MyRadioCard = ({ label, value, checked, onChange }) => {
    return (
      <Card
        sx={
          label === 'Professional Package'
            ? { margin: '30 0 0 0', height: 70 }
            : {
                mt: 3,
                height: 70
              }
        }
      >
        <CardContent
          sx={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Grid sx={{ display: 'flex' }}>
            <SubscriptionsIcon sx={{ fontSize: 40, color: 'grey' }} />
            <div className='vl' />
            <Grid sx={{ ml: 4 }}>
              <Typography variant='h6'>{label}</Typography>
              <Typography variant='caption'>More Information</Typography>
            </Grid>
          </Grid>
          <FormControlLabel
            // label={label}
            value={value}
            control={<Radio />}
            checked={checked}
            onChange={onChange}
          ></FormControlLabel>
        </CardContent>
      </Card>
    );
  };

  const handleCarriersSwitchOnchange = (event, SelectedApiCarrier, index) => {
    setCarriers(prevArray => {
      const newArray = [...prevArray];
      newArray[index].active = !newArray[index].active;
      handleActiveAllCarriersSwitch(newArray);
      return newArray;
    });
    console.log(carriers, 'handleSwitchToggle ');

    for (let i = 0; i < selectedCarriersIdArray.length; i++) {
      const carrierID = selectedCarriersIdArray[i].carrierId;
      const carrierStatus = selectedCarriersIdArray[i].assigned;
      console.log(carrierID, carrierStatus, 'carrierID');
      if (carrierID === SelectedApiCarrier?.id && carrierStatus) {
        selectedCarriersIdArray[i].assigned = false;
      }

      if (carrierID === SelectedApiCarrier?.id && !carrierStatus) {
        selectedCarriersIdArray[i].assigned = true;
      }
    }
    setSelectedCarriersIdArray(selectedCarriersIdArray);
  };

  const CarriersCard = ({ items, index }) => {
    return (
      <Grid
        sx={
          items?.active
            ? {
                outline: '1px solid #ebebeb',
                boxShadow: '0px 0px 5px 1px',
                m: 2,
                borderRadius: '4px',
                backgroundColor: '#ebebeb',
                // outline: '1px solid #b1b1b1',
                display: 'flex',
                justifyContent: 'space-between',
                width: '40vw'
              }
            : {
                m: 2,
                borderRadius: '4px',
                outline: '1px solid #b1b1b1',
                display: 'flex',
                justifyContent: 'space-between',
                width: '40vw'
              }
        }
      >
        <Grid sx={{ display: 'flex', alignItems: 'center' }}>
          <img
            style={{ width: 70 }}
            src={items.icon ? base_logo_url + items.icon : NoLogo}
            alt='AdminProfileImage'
          />{' '}
          <Grid>
            <Typography sx={{ ml: 2 }} variant='h6'>
              {items.name}
            </Typography>
            <Typography sx={{ ml: 2 }} variant='caption'>
              {items.cType}
            </Typography>
          </Grid>
        </Grid>

        <Grid sx={{ display: 'flex', alignItems: 'center' }}>
          <Switch
            checked={items?.active}
            onChange={event =>
              handleCarriersSwitchOnchange(event, items, index)
            }
          />
        </Grid>
      </Grid>
    );
  };
  const handleStateProvinceChange = (e, fieldName) => {
    if (fieldName === 'city') {
      const inputValue = e;

      setCity(inputValue);
      const regex = /^[a-zA-Z\s]+$/;
      let errors = {};
      if (regex.test(inputValue)) {
        setIsValidCity(false);
        setValidationErrors({
          ...validationErrors,
          cityError: null
        });
      } else {
        setIsValidCity(true);
        errors['cityError'] = 'Enter Valid City';
        setValidationErrors(errors);
      }
    } else if (fieldName === 'state') {
      const inputValue = e;
      setState(inputValue);
      const regex = /^[a-zA-Z\s]+$/;
      let errors = {};
      if (regex.test(inputValue)) {
        setIsValidState(false);
        setValidationErrors({
          ...validationErrors,
          stateError: null
        });
      } else {
        setIsValidState(true);
        errors['stateError'] = 'Enter Valid State or Province';
        setValidationErrors(errors);
      }
    } else {
      const inputValue = e;
      setCountry(inputValue);
      const regex = /^[a-zA-Z\s]+$/;
      let errors = {};
      if (regex.test(inputValue)) {
        setIsValidCountry(false);
        setValidationErrors({
          ...validationErrors,
          countryError: null
        });
      } else {
        setIsValidCountry(true);
        errors['countryError'] = 'Enter Valid Country';
        setValidationErrors(errors);
      }
    }
  };

  const handleNextButton = () => {};

  const handleAdminImageUpload = event => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setAdminImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const onCityStateZipChange = (event, values) => {
    if (values !== null) {
      setValidationErrors({
        ...validationErrors,
        cityStateZipFinalValue: null
      });
      GetLocationsJSON(values)
        .then(res => res.status === 200 && handleUSAddresses(res.data))
        .catch(err => {
          setShipFromloading(false);
          console.log(err, 'Get Locations Error');
        });
    }
    // setCityStateZipHoveredValue(filtered[0]);
    let FinalObj2 = {};
    if (values !== null) {
      setValidationErrors({
        ...validationErrors,
        cityStateZipFinalValue: null
      });
      setShipFromErr(false);
      setCityStateZipFinalValue(values);
      const tempData = values
        .split(' ')
        .map(item => item.trim())
        .filter(item => item !== '');
      if (tempData.length === 4) {
        const updatedErrors = { ...validationErrors, shipToErr: null };
        setValidationErrors(updatedErrors);
        const City = tempData[0];
        const State = tempData[1];
        const ZipCode = tempData[2];
        const CountryCode = tempData[3];

        FinalObj2 = {
          City,
          State,
          ZipCode,
          CountryCode
        };
        // setCityStateZipFinalAddress(FinalObj2);
      } else if (tempData.length === 6) {
        const City = tempData[0] + ' ' + tempData[1] + ' ' + tempData[2];
        const State = tempData[3];
        const ZipCode = tempData[4];
        const CountryCode = tempData[5];
        FinalObj2 = {
          City,
          State,
          ZipCode,
          CountryCode
        };
      } else {
        const City = tempData[0] + ' ' + tempData[1];
        const State = tempData[2];
        const ZipCode = tempData[3];
        const CountryCode = tempData[4];

        FinalObj2 = {
          City,
          State,
          ZipCode,
          CountryCode
        };

        // setCityStateZipFinalAddress(FinalObj2);
      }
      setCityStateZipFinalAddress(FinalObj2);
    } else {
      // setCityStateZipFinalAddress('');
      // setShipFromErr(true);
    }
  };
  const filterOptions = createFilterOptions({
    limit: 100
  });

  const handleStepperContent = (index, label) => {
    if (index === 0) {
      const handleAgencyStatusChange = (event, newValue) => {
        setAgencyActiveValue(newValue);
        if (newValue === 'Active') {
          setAgencyStatus(true);
        } else {
          setAgencyStatus(false);
        }
      };

      return (
        <Grid sx={{ height: '55vh' }}>
          <Grid sx={{ backgroundColor: '#e9e9e9', borderRadius: 2, mt: 3 }}>
            <Grid sx={{ height: 120 }}>
              <div>
                <input
                  type='file'
                  accept='image/*'
                  onChange={handleAgencyImageUpload}
                  style={{ display: 'none' }}
                  id='image-upload'
                />
                <label htmlFor='image-upload'>
                  {agencyImage ? (
                    <img
                      // htmlFor='image-upload'
                      src={agencyImage}
                      className='Admin_Img'
                      alt=''
                      width='200'
                      style={{
                        borderRadius: '50%',
                        objectFit: 'cover',
                        cursor: 'pointer'
                      }}
                    />
                  ) : (
                    <span
                      role='img'
                      aria-label='upload icon'
                      className='Admin_Img'
                      // htmlFor='image-upload'
                      style={{
                        display: 'inline-block',
                        borderRadius: '50%',
                        backgroundColor: '#e5e5e5',
                        textAlign: 'center',
                        lineHeight: '120px',
                        cursor: 'pointer'
                      }}
                    >
                      <UploadIcon />
                      Upload
                    </span>
                  )}
                </label>
                <Dialog
                  open={dialogOpen}
                  onClose={() => handleCancel()}
                  maxWidth='sm'
                  fullWidth
                >
                  <DialogTitle>Adjust Image</DialogTitle>
                  <DialogContent>
                    <div
                      style={{
                        position: 'relative',
                        height: 400,
                        background: '#333'
                      }}
                    >
                      <Cropper
                        image={tempImage}
                        crop={crop}
                        zoom={zoom}
                        aspect={1}
                        onCropChange={setCrop}
                        onZoomChange={setZoom}
                        onCropComplete={onCropComplete}
                      />
                    </div>
                    <Slider
                      value={zoom}
                      min={1}
                      max={3}
                      step={0.1}
                      aria-labelledby='zoom-slider'
                      onChange={(e, newValue) => setZoom(newValue)}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button onClick={handleConfirm} color='primary'>
                      Confirm
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            </Grid>
          </Grid>

          <Grid sx={{ mt: 7 }}>
            <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <TextField
                disabled={EditedData?.company ? true : false}
                variant='standard'
                FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                error={Boolean(validationErrors['companyNameError'])}
                helperText={validationErrors['companyNameError']}
                onChange={e => {
                  setCompanyName(e.target.value);
                  setValidationErrors({
                    ...validationErrors,
                    companyNameError: null
                  });
                }}
                value={companyName}
                required
                // InputLabelProps={{ style: { fontSize: 13 } }}
                inputProps={{ style: { fontSize: 13 } }}
                sx={{ width: '20vw' }}
                size='small'
                label='Company Name'
                type='text'
              />
              <Autocomplete
                sx={{ width: '20vw', mt: 0 }}
                size='small'
                value={agencyActiveValue}
                onChange={(event, newValue) =>
                  handleAgencyStatusChange(event, newValue)
                }
                renderOption={(PreviousProps, option) => (
                  <Box style={{ fontSize: 13 }} {...PreviousProps}>
                    {option}
                  </Box>
                )}
                options={options}
                renderInput={params => (
                  <TextField
                    label='Status'
                    variant='standard'
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      style: { fontSize: 13 }
                    }}
                  />
                )}
              />
            </Grid>
            <Grid
              sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}
            >
              <TextField
                sx={{ width: '20vw' }}
                variant='standard'
                onChange={e => setAddress1(e.target.value)}
                value={address1}
                fullWidth
                // InputLabelProps={{ style: { fontSize: 13 } }}
                inputProps={{ style: { fontSize: 13 } }}
                size='small'
                label='Address Line 1'
                type='text'
              />
              <TextField
                sx={{ width: '20vw' }}
                variant='standard'
                onChange={e => setAddress2(e.target.value)}
                value={address2}
                // InputLabelProps={{ style: { fontSize: 13 } }}
                inputProps={{ style: { fontSize: 13 } }}
                fullWidth
                size='small'
                label='Address Line 2'
                type='text'
              />
            </Grid>
            <Autocomplete
              fullWidth
              noOptionsText={
                shipCity.length !== 0 ? 'Loading...' : 'No Options'
              }
              loading={shipFromloading}
              onHighlightChange={(event, option) => {
                !cityStateZipHoveredValue &&
                  setCityStateZipHoveredValue(option);
              }}
              onChange={(event, newValue) =>
                onCityStateZipChange(event, newValue)
              }
              onInputChange={(event, newValue) =>
                onCityStateZipChange(event, newValue)
              }
              renderOption={(props, option) => (
                <Box style={{ fontSize: 13 }} {...props}>
                  {option}
                </Box>
              )}
              filterOptions={filterOptions}
              size='small'
              sx={{ mt: 4 }}
              options={shipCity}
              value={cityStateZipFinalValue}
              openOnFocus
              // onChange={onCityStateZipChange}
              renderInput={params => (
                <TextField
                  variant='standard'
                  onKeyDown={e => {
                    if (e.key === 'Tab' && cityStateZipHoveredValue) {
                      // e.preventDefault(); // prevent the default Tab behavior
                      onCityStateZipChange(e, cityStateZipHoveredValue);
                    }
                  }}
                  inputRef={selectShipTo}
                  // required={true}
                  {...params}
                  error={Boolean(validationErrors['cityStateZipFinalValue'])}
                  helperText={validationErrors['cityStateZipFinalValue']}
                  label='City / State / Zip / Country'
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  InputProps={{
                    style: {
                      fontSize: 13
                    },
                    ...params.InputProps
                  }}
                />
              )}
            />
            {/* <Grid
              sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}
            >
              <TextField
                variant='standard'
                FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                error={Boolean(validationErrors['zipError'])}
                helperText={validationErrors['zipError']}
                onChange={e => {
                  setZip(e.target.value);
                  setValidationErrors({
                    ...validationErrors,
                    zipError: null
                  });
                }}
                value={zip}
                required
                // InputLabelProps={{ style: { fontSize: 13 } }}
                inputProps={{ style: { fontSize: 13 } }}
                sx={{ width: '20vw' }}
                size='small'
                label='Zip'
                type='number'
              />
              <TextField
                variant='standard'
                FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                error={Boolean(validationErrors['cityError'])}
                helperText={validationErrors['cityError']}
                onChange={e => {
                  handleStateProvinceChange(e.target.value, 'city');
                }}
                value={city}
                required
                // InputLabelProps={{ style: { fontSize: 13 } }}
                inputProps={{ style: { fontSize: 13 } }}
                sx={{ width: '20vw' }}
                size='small'
                label='City'
                type='text'
              />
            </Grid>
            <Grid
              sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}
            >
              <TextField
                variant='standard'
                FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                error={Boolean(validationErrors['stateError'])}
                helperText={validationErrors['stateError']}
                onChange={e =>
                  handleStateProvinceChange(e.target.value, 'state')
                }
                value={state}
                required
                // InputLabelProps={{ style: { fontSize: 13 } }}
                inputProps={{ style: { fontSize: 13 } }}
                sx={{ width: '20vw', mr: 0 }}
                size='small'
                label='State / Province'
                type='text'
              />
              <TextField
                variant='standard'
                FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                error={Boolean(validationErrors['countryError'])}
                helperText={validationErrors['countryError']}
                onChange={e => {
                  handleStateProvinceChange(e.target.value, 'country');
                }}
                value={country}
                required
                // InputLabelProps={{ style: { fontSize: 13 } }}
                inputProps={{ style: { fontSize: 13 } }}
                sx={{ width: '20vw' }}
                size='small'
                label='Country'
                type='text'
              />
            </Grid> */}
          </Grid>
        </Grid>
      );
    }
    if (index === 1) {
      return (
        <Grid sx={{ height: '53vh' }}>
          {' '}
          <MyRadioCard
            label='Basic Package'
            value='Basic Package'
            checked={true}
            onChange={handleRadioChange}
          />
          {/* <MyRadioCard
            label='Standard Package'
            value='Standard Package'
            checked={selectedValue === 'Standard Package'}
            onChange={handleRadioChange}
          />
          <MyRadioCard
            label='Professional Package'
            value='Professional Package'
            checked={selectedValue === 'Professional Package'}
            onChange={handleRadioChange}
          /> */}
        </Grid>
      );
    }
    if (index === 2) {
      const handleChange = event => {
        setValue(event.target.value);
        if (event.target.value === 'All') {
          setValue('All');
          setCarriers(AllCarriers);
        }
        if (event.target.value === 'LTL') {
          setValue('LTL');
          setCarriers(LTLCarriers);
        }
        if (event.target.value === 'TL') {
          setValue('TL');
          setCarriers(TLCarriers);
        }
        if (event.target.value === 'Parcel') {
          setValue('Parcel');
          setCarriers(TankerCarriers);
        }
      };

      return (
        <Grid sx={{ height: '56vh' }}>
          <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby='demo-row-radio-buttons-group-label'
                name='row-radio-buttons-group'
                value={value}
                onChange={handleChange}
              >
                <FormControlLabel
                  value='All'
                  control={<Radio size='small' />}
                  label='All'
                />
                <FormControlLabel
                  value='LTL'
                  control={<Radio size='small' />}
                  label='LTL'
                />
                {/* <FormControlLabel
                  value='TL'
                  control={<Radio size='small' />}
                  label='TL'
                /> */}
                <FormControlLabel
                  value='Parcel'
                  control={<Radio size='small' />}
                  label='Parcel'
                />
              </RadioGroup>
            </FormControl>
            <Chip
              variant='outlined'
              color='success'
              label={
                <Grid sx={{ mt: 0, display: 'flex', alignItems: 'center' }}>
                  <Typography variant='body2'>Active All</Typography>
                  <Switch
                    size='small'
                    color='success'
                    checked={allCarriersChecked}
                    onChange={handleAllActiveCarriers}
                  />
                </Grid>
              }
            ></Chip>
          </Grid>
          <hr style={{ margin: 0 }} />
          <Grid
            sx={
              carriersLoader
                ? {
                    height: '55vh',
                    overflow: 'scroll',
                    overflowX: 'hidden',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }
                : {
                    height: '55vh',
                    overflow: 'scroll',
                    overflowX: 'hidden'
                  }
            }
          >
            <Grid>{carriersLoader && <CircularProgress />}</Grid>
            <Grid>
              {carriers.map((items, index) => (
                <CarriersCard items={items} index={index} />
              ))}
            </Grid>
          </Grid>
        </Grid>
      );
    }
    if (index === 3) {
      const handleAdminStatusChange = (event, newValue) => {
        setAdminActiveValue(newValue);
        if (newValue === 'Active') {
          setAdminStatus(true);
        } else {
          setAdminStatus(false);
        }
      };
      const handlePhoneNumberPaste = event => {
        // Prevent the default paste behavior
        event.preventDefault();

        // Get the pasted text from clipboard
        const pastedText = event.clipboardData.getData('text/plain');

        // Remove non-numeric characters from pasted text
        const cleanedText = pastedText.replace(/\D/g, '');

        // Apply formatting logic
        const formattedValue = cleanedText.replace(
          /^(\d{3})(\d{3})(\d{4})$/,
          '($1) $2-$3'
        );
        setPhoneNumber(formattedValue);

        // Perform validation on the formatted value
        const errors = {};
        const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
        const isValidPhoneNumber = phoneRegex.test(formattedValue);

        if (isValidPhoneNumber) {
          setPhoneNumberValidError(false);
          setValidationErrors({ ...validationErrors, phoneNumberError: null });
        } else {
          setPhoneNumberValidError(true);
          errors['phoneNumberError'] =
            'Enter Valid Phone Number (XXX) XXX-XXXX';
          setValidationErrors(errors);
        }
      };
      const handlePhoneNumberChange = event => {
        const inputValue = event.target.value;
        // Remove non-numeric characters and retain formatting
        const formattedValue = inputValue
          .replace(/\D/g, '')
          .replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3');
        setPhoneNumber(formattedValue);

        // Perform validation on the formatted value
        const errors = {};
        const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
        const isValidPhoneNumber = phoneRegex.test(formattedValue);

        if (isValidPhoneNumber) {
          setPhoneNumberValidError(false);
          setValidationErrors({ ...validationErrors, phoneNumberError: null });
        } else {
          setPhoneNumberValidError(true);
          errors['phoneNumberError'] =
            'Enter Valid Phone Number (XXX) XXX-XXXX';
          setValidationErrors(errors);
        }
      };
      const validatePhoneNumber = inputValue => {
        // Regular expression to match the USA phone number format (e.g., (123) 456-7890 or 123-456-7890)
        const phoneRegex = /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/;

        const isValidPhoneNumber = phoneRegex.test(inputValue);
        if (isValidPhoneNumber) {
          setValidationErrors({ ...validationErrors, phoneNumberError: null });
        }
        setIsValid(isValidPhoneNumber);
      };
      const handleEmailValidation = email => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const errors = {};
        setEmail(email);

        if (emailRegex.test(email)) {
          setIsValidEmail(true);
          setValidationErrors({
            ...validationErrors,
            emailError: null
          });
        } else {
          setIsValidEmail(false);
          errors['emailError'] =
            'Valid email, will be your agency admin account.';
        }
        setValidationErrors(errors);
      };
      if (createAgencyLoader) {
        return (
          <Grid
            sx={{
              display: 'flex',
              justifyContent: 'center',
              height: '55vh',
              alignItems: 'center'
            }}
          >
            <CircularProgress />
          </Grid>
        );
      } else
        return (
          <Grid sx={{ height: '55vh' }}>
            <Grid sx={{ backgroundColor: '#e6d4c2', borderRadius: 2, mt: 3 }}>
              <Grid sx={{ height: 120 }}>
                <div>
                  <input
                    // type='file'
                    // accept='image/*'
                    // onChange={handleAdminImageUpload}
                    style={{ display: 'none' }}
                    id='image-upload'
                  />
                  <label htmlFor='image-upload'>
                    <img
                      src={`data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"><text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle">${encodeURIComponent(
                        firstLetter + lastLetter
                      )}</text></svg>`}
                      // src={imageUrl}
                      alt={firstLetter}
                      style={imageStyle}
                    />
                  </label>
                </div>
              </Grid>
            </Grid>

            <Grid sx={{ mt: 7 }}>
              <Grid
                sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}
              >
                <TextField
                  // disabled={EditedData?.adminName ? true : false}
                  variant='standard'
                  FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                  error={Boolean(validationErrors['firstNameError'])}
                  helperText={validationErrors['firstNameError']}
                  onChange={e => {
                    const inputValue = e.target.value;
                    if (/^[a-zA-Z]*$/.test(inputValue)) {
                      setFirstName(inputValue);
                      setValidationErrors({
                        ...validationErrors,
                        firstNameError: null
                      });
                    }
                  }}
                  value={firstName}
                  required
                  // InputLabelProps={{ style: { fontSize: 13 } }}
                  inputProps={{ style: { fontSize: 13 } }}
                  sx={{ width: '20vw' }}
                  size='small'
                  label='First Name'
                  type='text'
                />
                <TextField
                  // disabled={EditedData?.adminName ? true : false}
                  variant='standard'
                  FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                  error={Boolean(validationErrors['lastNameError'])}
                  helperText={validationErrors['lastNameError']}
                  onChange={e => {
                    const inputValue = e.target.value;
                    if (/^[a-zA-Z]*$/.test(inputValue)) {
                      setLastName(inputValue);
                      setValidationErrors({
                        ...validationErrors,
                        lastNameError: null
                      });
                    }
                  }}
                  value={lastName}
                  required
                  // InputLabelProps={{ style: { fontSize: 13 } }}
                  inputProps={{ style: { fontSize: 13 } }}
                  sx={{ width: '20vw' }}
                  size='small'
                  label='Last Name'
                  type='text'
                />
              </Grid>
              <Grid
                sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}
              >
                <TextField
                  disabled={EditedData?.adminName ? true : false}
                  variant='standard'
                  FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                  error={Boolean(validationErrors['emailError'])}
                  helperText={validationErrors['emailError']}
                  onChange={e => handleEmailValidation(e.target.value)}
                  value={email}
                  required
                  // InputLabelProps={{ style: { fontSize: 13 } }}
                  inputProps={{ style: { fontSize: 13 } }}
                  sx={{ width: '20vw', mr: 0 }}
                  size='small'
                  label='Email Address'
                  type='email'
                />
                <TextField
                  // disabled={EditedData?.adminName ? true : false}
                  variant='standard'
                  FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                  error={Boolean(validationErrors['phoneNumberError'])}
                  helperText={validationErrors['phoneNumberError']}
                  onChange={handlePhoneNumberChange}
                  onPaste={handlePhoneNumberPaste}
                  value={phoneNumber}
                  required
                  // InputLabelProps={{ style: { fontSize: 13 } }}
                  sx={{ width: '20vw' }}
                  size='small'
                  label='Phone Number'
                  type='text'
                  InputLabelProps={{
                    shrink: phoneNumber ? true : false
                  }}
                  inputProps={{
                    maxLength: 10,
                    style: { fontSize: 13 } // Set the maximum length to 10 characters
                  }}
                />
              </Grid>
              <Grid
                sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}
              >
                <Autocomplete
                  // disabled={EditedData?.adminName ? true : false}
                  sx={{ width: '20vw' }}
                  size='small'
                  value={adminActiveValue}
                  onChange={(event, newValue) =>
                    handleAdminStatusChange(event, newValue)
                  }
                  renderOption={(PreviousProps, option) => (
                    <Box style={{ fontSize: 13 }} {...PreviousProps}>
                      {option}
                    </Box>
                  )}
                  options={options}
                  renderInput={params => (
                    <TextField
                      label='Status'
                      variant='standard'
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        style: { fontSize: 13 }
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        );
    }
  };
  return (
    <Box
      sx={{
        p: 2,
        minHeight: '55vh',
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <ToastContainer
        style={{ width: 'auto' }}
        position='top-center'
        autoClose={3800}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Paper className='Grid_Item'>
        <Box sx={{ width: '42vw' }}>
          <Stepper
            // className='PickupStepperParent'
            activeStep={activeStep}
            alternativeLabel
            // nonLinear
          >
            {CreateAgencySteps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};

              return (
                <Step key={label} {...stepProps}>
                  <StepButton
                    color='inherit'
                    onClick={handleStep(index, label)}
                  >
                    {label}
                  </StepButton>
                </Step>
              );
            })}
          </Stepper>
          {handleStepperContent(activeStep, activeTabLabel)}
          <div className={'NextBtnDiv'}>
            {activeStep !== 0 && (
              <Button
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
                size='small'
                onClick={shipmentFailed ? handleBackShipment : handleBack}
                // className='BtnColor'
                variant='outlined'
              >
                <ChevronLeftIcon /> Previous
              </Button>
            )}

            <Button
              disabled={createAgencyOnce}
              size='small'
              sx={{
                ml: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              onClick={() => handleNext(activeStep)}
              className='BtnColor'
              variant='contained'
            >
              {' '}
              {activeStep === CreateAgencySteps.length - 1
                ? EditedData
                  ? 'Update'
                  : 'Create'
                : 'Continue'}
              <ChevronRightIcon />
            </Button>
          </div>
        </Box>
      </Paper>
    </Box>
  );
}
