import { base_url } from './constants';
const axios = require('axios');

const GetAuthToken = () => {
  // const userAuthToken = useSelector(state => state.userDetails.token);
  return localStorage.getItem('AdminAuthToken');
  // return userAuthToken;
};

const AgencyLoginHeader = axios.create({
  baseURL: base_url,
  responseType: 'json'
});

// Add an interceptor to update the Authorization header before each request
AgencyLoginHeader.interceptors.request.use(
  async config => {
    const authToken = GetAuthToken();
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export const USER_API = axios.create({
  baseURL: `${base_url}User`,
  responseType: 'json',
  headers: {
    Authorization: 'bearer ' + localStorage.getItem('AccessToken')
  }
});
export const AdminLogin = async (username, pass) => {
  return await axios.post(`${base_url}Admin/SignIn`, {
    loginId: username,
    password: pass
  });
};

export const AdminLogout = async (username, pass) => {
  return await axios.post(`${base_url}Admin/SignOut`, {
    loginId: username,
    password: pass
  });
};

export const AdminGetProfile = async UserLoggedInEmail => {
  return await AgencyLoginHeader.get(
    `${base_url}Admin/GetProfile?loginId=${UserLoggedInEmail}`
  );
};

export const AgencyAdminGetProfile = async AgencyLoginID => {
  return await AgencyLoginHeader.get(
    `${base_url}Admin/GetAgencyUserProfile?loginId=${AgencyLoginID}`
  );
};

export const AdminForgot = async loginId => {
  return await axios.post(`${base_url}Admin/Forgot`, {
    loginId: loginId
  });
};

// key optinal
// current password necessary
// logged In User Pass Change

export const AdminUpadatePassword = async (
  newPass,
  currentPass,
  UserLoggedInEmail
) => {
  return await AgencyLoginHeader.post(`${base_url}Admin/UpadatePassword`, {
    loginId: UserLoggedInEmail,
    passwordKey: '',
    password: newPass,
    currentPassword: currentPass
  });
};

// Without Logged In

export const AdminUpadateForgotPassword = async (
  confirmPassword,
  ForgotPassKey,
  LoginEmail
) => {
  return await axios.post(`${base_url}Admin/UpadateForgotPassword`, {
    loginId: LoginEmail,
    passwordKey: ForgotPassKey,
    password: confirmPassword,
    currentPassword: ''
  });
};

export const AdminUpdateProfile = async UpdatedProfileObj => {
  return await AgencyLoginHeader.post(`${base_url}Admin/UpdateProfile`, {
    UpdatedProfileObj
  });
};

export const AdminGetCarriers = async carrierType => {
  return await AgencyLoginHeader.get(
    `${base_url}Admin/GetCarriers?carrierType=${carrierType}`
  );
};

export const CreateOrUpdateAgency = async CreateOrUpdateAgencyObj => {
  return await AgencyLoginHeader.post(
    `${base_url}Admin/CreateOrUpdateAgency`,
    CreateOrUpdateAgencyObj
  );
};

export const GetAgencies = async () => {
  return await AgencyLoginHeader.get(`${base_url}Admin/GetAgencies`);
};

export const UploadAgencyLogo = async formData => {
  return await AgencyLoginHeader.post(
    `${base_url}Admin/UploadAgencyLogo`,
    formData
  );
};

export const CreateAdminRole = async CreateAdminRoleObj => {
  return await AgencyLoginHeader.post(
    `${base_url}Admin/CreateAdminRole`,
    CreateAdminRoleObj
  );
};

export const CreateOrUpdateCarrier = async obj => {
  return await AgencyLoginHeader.post(
    `${base_url}Admin/CreateOrUpdateCarrier`,
    obj
  );
};
export const GetLocationsJSON = async squery => {
  return await axios.get(
    // `${base_url}Carriers/SearchLocation?query=${squery} `
    `https://api.tms.shiptechpro.com/Carriers/SearchLocation?query=${squery}`
  );
};
